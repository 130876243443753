// Animation css
@import '~animate.css/animate.min.css';

// Bootstrap Layout scss
@import 'node_modules/bootstrap/scss/bootstrap.scss';

// Owl Carousel
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Themify icon scss
@import 'themify';

// Theme Scss
@import 'theme/variables';
:root {
  --theme-deafult: #cd0001;
  // This gradient color only for gym and marijuana layout
  --theme-gradient1: #01effc;
  --theme-gradient2: #485ff2;
}
@import 'theme/style';
@import 'theme/theme-dark';
@import 'theme/menu';
@import 'theme/responsive';

// Toastr Css
@import '../../../node_modules/ngx-toastr/toastr.css';

// Rating Scss
@import 'node_modules/ngx-bar-rating/themes/br-default-theme';